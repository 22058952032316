import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import "./styles.scss";
import { postData } from "../../helpers/apiService";

const StopwatchPage = () => {
  const navigate = useNavigate();
  const [time, setTime] = useState(0);
  const [isRunning, setIsRunning] = useState(false);
  const [reseted, setReseted] = useState(false);

  useEffect(() => {
    let interval = null;

    if (isRunning) {
      interval = setInterval(() => {
        setTime((prevTime) => prevTime + 10);
      }, 10); // change the interval to 1 millisecond
    } else if (!isRunning && time !== 0) {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [isRunning, time]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.code === "Space") {
        setIsRunning((prevState) => !prevState);
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const handleStart = () => {
    setIsRunning(true);
  };

  const handleStop = () => {
    setIsRunning(false);
  };

  const handleReset = () => {
    setIsRunning(false);
    setReseted(true);
    setTime(0);
  };

  const handleFinish = async () => {
    const data = JSON.parse(localStorage.getItem("data"));
    const response = await postData({
      email: data.email,
      name: data.name,
      phone: data.phone,
      country_id: data.country,
      hospital: data.hospital,
      score: time,
      event_id: 1,
    });
    // update localstorage data add score
    localStorage.setItem("data", JSON.stringify({ ...data, score: time }));
    navigate("/leaderboard");
  };

  const formatTime = (time) => {
    const getMilliseconds = `00${time % 1000}`.slice(-3, -1);
    const getSeconds = `0${Math.floor((time / 1000) % 60)}`.slice(-2);
    const minutes = Math.floor(time / 60000);
    const getMinutes = `0${minutes % 60}`.slice(-2);
    const getHours = `0${Math.floor(time / 3600000)}`.slice(-2);
    // return `${getHours}:${getMinutes}:${getSeconds}:${getMilliseconds}`;
    return `${getMinutes}:${getSeconds}:${getMilliseconds}`;
  };

  return (
    <div className="flex justify-center items-center h-screen">
      <div>
        <div className="flex justify-center items-center mb-8">
          <img src="/BYD 1.png" width={200} alt="logo" className="py-5" />
        </div>
        <div className="stopwatch">
          <label>
            <div
              className="stopwatch__button"
              title="Click to pause"
              onClick={handleStart}
            ></div>
            <div className="stopwatch__display">
              <div className="time-text flex text-center ">
                {formatTime(time)}
              </div>
              <p className="text-sm font-bold text-[#969A9D]">
                Press Space for {isRunning ? "Stop" : "Start"}
              </p>
            </div>
          </label>
        </div>
        <div className="flex justify-center items-center mt-8">
          <button
            className="bg-red-500 px-10 py-3 rounded-3xl text-white font-bold mb-8"
            onClick={handleFinish}
          >
            FINISH
          </button>
        </div>
      </div>
    </div>
  );
};

export default StopwatchPage;
