import React, { useEffect } from 'react';
import Popup from './index'

function PopupSwal({ title = "", desc ="", onAction }) {
    useEffect(() => {
        const autoAction = () => {
            Popup.close()
            onAction()
        };
        
        const timeoutId = setTimeout(autoAction, 2000);
    
        return () => clearTimeout(timeoutId);
    }, []);

    return (
        <div className={'show-popup'}>
            <div className="bg-white rounded-lg p-8 py-0" style={{width: "500px"}}>
                <div className="popup-content">
                    <div className="flex justify-center flex-col items-center pb-3">
                        <h1 className='font-bold'>{title}</h1>
                        <p>{desc}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PopupSwal