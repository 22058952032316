// Axios
import axios from 'axios';

// Redux
import { applyMiddleware, combineReducers, createStore } from 'redux';
import { Provider } from 'react-redux';
import { composeWithDevTools } from '@redux-devtools/extension';

// Reducer
import authReducer from './auth';

// Function
export * from './auth/functions'

// Base URL
export const baseUrl = 'http://localhost:8080';

// Base Axios
export const baseAxios = axios.create({ 
    baseURL: baseUrl,
    timeout: 10000,
});

const rootReducer = combineReducers({
    // reducer
    authReducer,
});

export default function ReduxState(props) {
    let store = createStore(rootReducer, composeWithDevTools(applyMiddleware()));

    // FOR PRODUCTION
    // let store=createStore(rootReducer,applyMiddleware());

    return (
        <Provider store={store}>
            {props.children}
        </Provider>
    );
}
