import React from 'react';
import Popup from './index'

function PopupLoading({ children }) {
    React.useEffect(() => {
        return () => {
            Popup.close()
        }
    }, [])

    return (
        <div className={'show-popup'}>
            <div className="bg-white rounded-lg p-8" style={{width: "500px"}}>
                <div className="popup-content">
                    <div className="flex justify-center">
                        <div className="loaderSwal loaderSwal-1">
                            <div className="loader-outterSwal"></div>
                            <div className="loader-innerSwal"></div>
                        </div>
                    </div>
                    <h2 className="text-[#0066b3] text-center mt-3">Harap Tunggu ...</h2>
                </div>
            </div>
        </div>
    )
}

export default PopupLoading