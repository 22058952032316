import { baseAxios } from "../index";

// IMPORT ERR HANDLER
import errorHandler from "../errorHandler";

// IMPORT COMPONENTS
import Popup from "../../components/popupSwall";

// LOGIN
export const sendDataStopwatch = async (data, cb, navigate) => {
  console.log(data);
  try {
    const dataMasuk = await baseAxios.post(
      "/postData",
      data
    );
    if (dataMasuk.status === 201) {
      Popup.swal({
        title: "Berhasil!",
        text: "Berhasil stop. Data Saved.",
        onAction: () => {
          
        }
      });
    }
  } catch (err) {
    errorHandler(err);
  }
  // Popup.showLoading();
  
};
// END LOGIN