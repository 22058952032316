import { createRoot } from 'react-dom/client';

// Import Style
import './style.scss';

// Import Popup
import PopupLoading from './PopupLoading';
import PopupSwal from './PopupSwal';

export default {
  close: () => {
    document.body.style.overflow = 'auto';
    var popup = document.getElementById('pop-up');
    if (popup) popup.remove();
    var loading = document.getElementById('loading');
    if (loading) loading.remove();
  },

  showLoading: () => {
    let div = document.createElement('div');
    let rootDiv = document.getElementById('root');
    div.setAttribute('id', 'loading');
    document.body.style.overflow = 'hidden';
    document.body.appendChild(div)
    rootDiv.style.position = "relative";
    rootDiv.style.zIndex = "1";
    const container = document.getElementById('loading');
    const root = createRoot(container);
    root.render(<PopupLoading />);
  },

  swal: ({ title, desc, onAction }) => {
    let div = document.createElement('div');
    let rootDiv = document.getElementById('root');
    div.setAttribute('id', 'loading');
    document.body.style.overflow = 'hidden';
    document.body.appendChild(div)
    rootDiv.style.position = "relative";
    rootDiv.style.zIndex = "1";
    const container = document.getElementById('loading');
    const root = createRoot(container);
    root.render(<PopupSwal 
      title={title}
      desc={desc}
      onAction={onAction}
    />);
  },
};
