import React, { useRef, useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

// IMPORT PAGES
import LoginPage from "./pages/auth/Login";
import StopwatchPage from "./pages/stopwatch";
import Guidance from "./pages/guidance";
import Leaderboard from "./pages/leaderboard";

function App() {
  const audioRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(true);
  const [hasInteracted, setHasInteracted] = useState(false);

  useEffect(() => {
    const handleUserInteraction = () => {
      if (audioRef.current) {
        audioRef.current.play().catch(error => {
          console.log('Failed to play audio:', error);
        });
      }
      // Remove the event listener after the first interaction
      document.removeEventListener('click', handleUserInteraction);
    };

    document.addEventListener('click', handleUserInteraction);

    return () => {
      document.removeEventListener('click', handleUserInteraction);
    };
  }, []);

  const togglePlayPause = () => {
    if (!hasInteracted) {
      audioRef.current.play().then(() => {
        setHasInteracted(true);
        setIsPlaying(true);
      }).catch((error) => {
        console.error("Playback failed:", error);
      });
    } else if (isPlaying) {
      audioRef.current.pause();
      setIsPlaying(false);
    } else {
      audioRef.current.play().then(() => {
        setIsPlaying(true);
      }).catch((error) => {
        console.error("Playback failed:", error);
      });
    }
  };

  return (
    <div className="App">
      <button onClick={togglePlayPause} className='absolute bg-gray-200 py-2 px-1 text-4xl rounded-full ml-4 mt-4'>
        {isPlaying ? '🔈' : '🔇'}
      </button>
      <audio ref={audioRef} autoPlay loop>
        <source src="/1 Min Music - Lightfox - Pluck.mp3" type="audio/mpeg" />
        Your browser does not support the audio element.
      </audio>
      <Router>
        <Routes>
          <Route element={<LoginPage />} path="/" exact />
          <Route element={<StopwatchPage />} path="/stopwatch" />
          <Route element={<Guidance />} path="/guidance" />
          <Route element={<Leaderboard />} path="/leaderboard" />
          <Route element={<div>404 Not Found</div>} path="*" />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
