import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: 'https://be.dexa-game.com/', // Ganti dengan baseURL API Anda
  timeout: 10000, // Waktu timeout dalam milidetik
  headers: {
    'Content-Type': 'application/json',
    // Tambahkan header default lainnya jika diperlukan
  },
});

// Tambahkan interceptor untuk menangani request sebelum dikirim
axiosInstance.interceptors.request.use(
  config => {
    // Lakukan sesuatu sebelum request dikirim, misalnya menambahkan token
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  error => {
    // Tangani error request
    return Promise.reject(error);
  }
);

// Tambahkan interceptor untuk menangani response setelah diterima
axiosInstance.interceptors.response.use(
  response => {
    // Lakukan sesuatu dengan data response
    return response;
  },
  error => {
    // Tangani error response
    return Promise.reject(error);
  }
);

export default axiosInstance;
