import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Swal from "sweetalert2";

// IMPORT STYLE
import "./styles/login.modul.scss";

const LoginPage = () => {
  const navigate = useNavigate();
  const [nama, setNama] = useState("");
  const [rumahSakit, setRumahSakit] = useState("");
  const [noTelp, setNoTelp] = useState("");
  const [email, setEmail] = useState("");
  const [country, setCountry] = useState("");

  const sendLogin = async (e) => {
    e.preventDefault();

    if (!nama || !rumahSakit || !noTelp || !email) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please fill in all fields!",
      });
      return;
    }

    // validate email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please enter a valid email address!",
      });
      return;
    }

    if (noTelp.length < 9) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please enter a valid phone number!",
      });
      return;
    }

    const myObject = {
      name: nama,
      hospital: rumahSakit,
      phone: noTelp,
      email: email,
      country,
    };
    localStorage.setItem("data", JSON.stringify(myObject));
    localStorage.setItem("nama", nama);
    navigate("/guidance");
  };
  // END LOGIN

  return (
    <section className="section-header">
      <div className="min-h-screen py-6 flex flex-col justify-center sm:py-12">
        <div className="relative py-3 sm:max-w-xl sm:mx-auto">
          <div className="relative px-8 py-10 bg-[#E0E0E0] shadow-2xl sm:px-10 sm:pt-6 sm:py-10 sm:min-w-[380px] border-4 border-black">
            <div className="max-w-md mx-auto">
              <div className="labelAuth">
                <label>
                  <div
                    className="labelAuth__button"
                    title="Click to pause"
                  ></div>
                  <div className="labelAuth__display">
                    <img src="/BYD 1.png" width={200} alt="logo" />
                  </div>
                </label>
              </div>

              <div className="divide-y divide-gray-200">
                <div className="py-8 text-base leading-6 space-y-4 text-gray-700 sm:text-lg sm:leading-7">
                  {/* NAME */}
                  <div className="relative">
                    <input
                      autoComplete="off"
                      id="nama_input"
                      name="nama_input"
                      type="text"
                      className="bg-transparent peer placeholder-transparent h-10 w-full border-b-2 border-black text-gray-900 focus:outline-none focus:borer-rose-600 text-sm"
                      placeholder="Nama"
                      value={nama}
                      onChange={(e) => setNama(e.target.value)}
                    />
                    <label
                      htmlFor="nama_input"
                      className="cursor-text absolute left-0 -top-3.5 text-black text-xs peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-black peer-focus:text-xs"
                    >
                      Nama
                    </label>
                  </div>
                  {/* END NAME */}

                  {/* RUMAH SAKIT */}
                  <div className="relative">
                    <input
                      autoComplete="off"
                      id="rumah_sakit_input"
                      name="rumah_sakit_input"
                      type="text"
                      className="bg-transparent peer placeholder-transparent h-10 w-full border-b-2 border-black text-gray-900 focus:outline-none focus:borer-rose-600 text-sm"
                      placeholder="Rumah Sakit"
                      value={rumahSakit}
                      onChange={(e) => setRumahSakit(e.target.value)}
                    />
                    <label
                      htmlFor="rumah_sakit_input"
                      className="cursor-text absolute left-0 -top-3.5 text-black text-xs peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-black peer-focus:text-xs"
                    >
                      Rumah Sakit
                    </label>
                  </div>
                  {/* END RUMAH SAKIT */}

                  {/* NOMOR TELEPON */}
                  <div className="w-full">
                    <label
                      className="block text-black text-xs mb-1"
                      htmlFor="phone"
                    >
                      Phone Number
                    </label>
                    <PhoneInput
                      country={"id"}
                      value={noTelp}
                      onChange={(value, country) => {
                        console.log("country => ", country);
                        setCountry(country.name)
                        setNoTelp(value)
                      }}
                      inputProps={{
                        name: "phone",
                        required: true,
                        autoFocus: true,
                      }}
                      inputClass="w-full px-3 py-2"
                      containerClass="mb-4 custom-phone-input"
                      buttonClass="custom-phone-button"
                    />
                  </div>
                  {/* END NOMOR TELEPON */}

                  {/* EMAIL */}
                  <div className="relative">
                    <input
                      autoComplete="off"
                      id="email_input"
                      name="email_input"
                      type="email"
                      className="bg-transparent peer placeholder-transparent h-10 w-full border-b-2 border-black text-gray-900 focus:outline-none focus:borer-rose-600 text-sm"
                      placeholder="Email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <label
                      htmlFor="email_input"
                      className="cursor-text absolute left-0 -top-3.5 text-black text-xs peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-black peer-focus:text-xs"
                    >
                      Email
                    </label>
                  </div>
                  {/* END EMAIL */}

                  <div className="relative">
                    <button
                      onClick={sendLogin}
                      className="bg-[#D32F2F] hover:bg-[#fd7c7c] text-black px-2 py-1 w-full border-4 border-black"
                    >
                      Start
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LoginPage;
