import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getAllLeaderBoard, getLeaderboardByEventId } from "../../helpers/apiService";

const leaderboardDataEmoji = ["🏆", "🥈", "🥉"];

const Leaderboard = () => {
  const navigate = useNavigate();
  const [leaderboardData, setLeaderboardData] = useState([]);
  const [dataLocal, setDataLocal] = useState(
    JSON.parse(localStorage.getItem("data"))
  );

  useEffect(() => {
    const fetchData = async () => {
      const response = await getLeaderboardByEventId(1);
      // const response = await getAllLeaderBoard();
      setLeaderboardData(
        response
          .sort((a, b) => a.score - b.score)
          .map((entry, index) => ({
            rank: index + 1,
            name: entry.name,
            score: formatTime(entry.score),
            emoji: index < 3 ? leaderboardDataEmoji[index] : "",
            email: entry.email,
          }))
      );
    };
    fetchData();
  }, []);

  const formatTime = (time) => {
    const getMilliseconds = `00${time % 1000}`.slice(-3, -1);
    const getSeconds = `0${Math.floor((time / 1000) % 60)}`.slice(-2);
    const minutes = Math.floor(time / 60000);
    const getMinutes = `0${minutes % 60}`.slice(-2);
    return `${getMinutes}:${getSeconds}:${getMilliseconds}`;
  };

  return (
    <div className="flex justify-center items-center">
      <div className="container w-[1000px]">
        <div className="container mx-auto p-4">
          <div className="flex justify-center items-center mb-8">
            <img src="/BYD 1.png" width={200} alt="logo" className="py-5" />
          </div>
          <div className="flex items-center justify-between">
            <h1 className="text-2xl font-bold mb-4">Leaderboard</h1>
            <button
              className="bg-red-500 px-8 py-2 rounded-3xl text-white font-bold mb-6"
              onClick={() => navigate("/")}
            >
              HOME
            </button>
          </div>
          <table className="min-w-full bg-white">
            <thead>
              <tr>
                <th className="py-2 px-4 bg-gray-200">Rank</th>
                <th className="py-2 px-4 bg-gray-200">Name</th>
                <th className="py-2 px-4 bg-gray-200">Time</th>
              </tr>
            </thead>
            <tbody>
              {leaderboardData.map((entry) => (
                <tr
                  key={entry.rank}
                  className={`text-center ${entry.email === dataLocal?.email && "bg-blue-500"}`}
                >
                  <td className="py-2 px-4 border">{entry.rank}</td>
                  <td className="py-2 px-4 border">{`${entry.name} ${entry.emoji}`}</td>
                  <td className="py-2 px-4 border">{entry.score}</td>
                </tr>
              ))}
              {dataLocal && !leaderboardData.find(
                (entry) => entry.email === dataLocal.email
              ) && (
                <tr className="text-center bg-blue-500">
                  <td className="py-2 px-4 border">...</td>
                  <td className="py-2 px-4 border">{`${dataLocal.name}`}</td>
                  <td className="py-2 px-4 border">
                    {formatTime(dataLocal.score)}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Leaderboard;
