import axiosInstance from '././axios';

export const postData = async (data) => {
  try {
    const response = await axiosInstance.post('/leaderboards', data);
    console.log('response => ', response);
    return response.data;
  } catch (error) {
    console.error('Error fetching example data:', error);
    throw error;
  }
};

// Contoh fungsi untuk melakukan POST request
export const getAllLeaderBoard = async () => {
  try {
    const response = await axiosInstance.get('/leaderboards');
    return response.data;
  } catch (error) {
    console.error('Error posting example data:', error);
    throw error;
  }
};

export const getLeaderboardByEventId = async (eventId) => {
  try {
    const response = await axiosInstance.get(`/leaderboards/event/${eventId}`);
    return response.data;
  } catch (error) {
    console.error('Error posting example data:', error);
    throw error;
  }
};
