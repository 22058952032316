import Popup from '../components/popupSwall'
export const errorHandler = (err, func) => {
    Popup.close()
    if(err.response){
        if(err.response.status === 401){
            
            Popup.fire({
                title: "Gagal!",
                text: "Unauthorized",
                onClickOk: () => {
                    localStorage.removeItem('key')
                    localStorage.removeItem('stopwatch')
                    window.open(window.location.origin+"/","_self")
                }
            })
        } else if(err.response.status === 404){
            Popup.fire({
                title: 'Gagal!',
                text: "Server sedang sibuk!",
                // onClickOk: () => {
                //     window.open(window.location.origin+"/404","_self")
                // }
            })
        }else if(err.response.status === 500){
            Popup.fire({
                title: 'Gagal!',
                text: "Internal Server Error!",
            })
        }
        else {
            Popup.fire({
                title: "Gagal!",
                text: err.response.data.message,
                onClickOk: func ? func : undefined
            })
        }
    }
    else {
        Popup.fire({
            title: 'Gagal!',
            text: "Server timeout",
            onClickOk: () => {
                localStorage.removeItem('key')
                localStorage.removeItem('stopwatch')
                window.open(window.location.origin+"/login","_self")
            }
        })
    }
}

export default errorHandler;