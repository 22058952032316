import React, { useEffect, useState } from "react";
import AOS from "aos";
import { useNavigate } from "react-router-dom";
import "aos/dist/aos.css";

const Guidance = () => {
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  useEffect(() => {
    AOS.init({
      duration: 1200, // Animation duration in milliseconds
    });
  }, []);

  return (
    <div className="flex justify-center items-center h-screen">
      <div className="container w-[1000px]">
        {step === 1 && (
          <div className="flex justify-center items-center flex-col">
            <img
              src="/BYD 1.png"
              width={200}
              alt="logo"
              className="py-5"
              data-aos="zoom-in"
            />
            <h1 className="text-4xl font-bold" data-aos="zoom-in">
              Selamat Datang di BYD - Build Your Diviti!
            </h1>
            <p className="py-5 text-center" data-aos="fade-up">
              {`Halo dan selamat datang ${localStorage.getItem("nama")} Dokter di dunia BYD - Build Your Diviti! Mari bergabung dalam permainan seru ini. Dalam permainan ini anda akan di challenge untuk merakit perangkat Diviti. Sebelum kita mulai, mari kita pelajari beberapa panduan berikut.`}
            </p>
            <button
              className="bg-red-500 px-10 py-3 rounded-3xl text-white font-bold"
              onClick={() => setStep(2)}
              data-aos="fade-up"
            >
              NEXT
            </button>
          </div>
        )}

        {step === 2 && (
          <div>
            <img
              src="/BYD 1.png"
              width={200}
              alt="logo"
              className="py-5"
              data-aos="zoom-in"
            />
            <h2 className="text-2xl font-bold mt-8 mb-3" data-aos="fade-right">
              Panduan cara menyusun Diviti :
            </h2>
            <div className="text-start" data-aos="fade-left">
              <p>
                Diviti terdiri dari tiga bagian penting: Prefilled Syringe
                Diviti, Plunger, dan Security Sleeve.
              </p>
              <h3 className="text-xl font-bold mt-5" data-aos="fade-right">
                Bagian yang Dibutuhkan:
              </h3>
              <div className="grid grid-cols-3 gap-4 py-5">
                <div
                  className="flex flex-col justify-center items-center bg-red-500 p-5 rounded-2xl bg-cover "
                  style={{ backgroundImage: 'url("/card-img-bg.png")' }}
                  data-aos="zoom-in"
                  data-aos-duration="1000"
                >
                  <img
                    src="/Prefilled Syringe Diviti.png"
                    alt="Prefilled Syringe Diviti"
                    className="w-[200px] h-[200px] object-contain"
                  />
                  <p className="text-center font-bold text-xl text-white">
                    Prefilled Syringe Diviti
                  </p>
                </div>
                <div
                  className="flex flex-col justify-center items-center bg-red-500 p-5 rounded-2xl bg-cover "
                  style={{ backgroundImage: 'url("/card-img-bg.png")' }}
                  data-aos="zoom-in"
                  data-aos-duration="2000"
                >
                  <img
                    src="/Plunger.png"
                    alt="Plunger"
                    className="w-[200px] h-[200px] object-contain"
                  />
                  <p className="text-center font-bold text-xl text-white">
                    Plunger
                  </p>
                </div>
                <div
                  className="flex flex-col justify-center items-center bg-red-500 p-5 rounded-2xl bg-cover "
                  style={{ backgroundImage: 'url("/card-img-bg.png")' }}
                  data-aos="zoom-in"
                  data-aos-duration="3000"
                >
                  <img
                    src="/Security Sleeve.png"
                    alt="Security Sleeve"
                    className="w-[200px] h-[200px] object-contain"
                  />
                  <p className="text-center font-bold text-xl text-white">
                    Security Sleeve
                  </p>
                </div>
              </div>

              <button
                className="bg-red-500 px-10 py-3 rounded-3xl text-white font-bold mb-8"
                onClick={() => setStep(3)}
              >
                NEXT
              </button>
            </div>
          </div>
        )}

        {step === 3 && (
          <div>
            <img
              src="/BYD 1.png"
              width={200}
              alt="logo"
              className="py-5"
              data-aos="zoom-in"
            />
            <p className="pb-3" data-aos="fade-left">
              Ikuti setiap langkah dengan cermat untuk memastikan perangkat
              tersusun dengan benar dan aman.
            </p>
            <div className="grid grid-cols-2 gap-4">
              <div className="flex items-center justify-center">
                <video
                  autoPlay
                  className="rounded-3xl mp-5 w-full object-cover"
                  controls
                  data-aos="fade-up"
                  data-aos-duration="4000"
                >
                  <source src="/tutorial.mp4" type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>

              <div className="ml-5">
                <h2 class="text-2xl font-bold mb-2" data-aos="fade-right">
                  Langkah-langkah:
                </h2>
                <ol class="list-decimal">
                  <li class="mb-4">
                    <h3 class="text-lg" data-aos="fade-right">
                      Ambil Prefilled Syringe Diviti
                    </h3>
                  </li>
                  <li class="mb-4">
                    <h3 class="text-lg" data-aos="fade-right">
                      Masukkan Plunger ke dalam Prefilled Syringe dengan memutar
                      searah jarum jam hingga terpasang baik di dalam prefilled
                      syringe
                    </h3>
                  </li>
                  <li class="mb-4">
                    <h3 class="text-lg" data-aos="fade-right">
                      Masukkan Prefilled Syringe ke dalam Security Sleeve, tekan
                      prefilled syringe hingga terdengan bunyi ‘klik’
                    </h3>
                  </li>
                  <li class="mb-4">
                    <h3 class="text-lg" data-aos="fade-right">
                      Diviti anda sudah terpasang
                    </h3>
                  </li>
                </ol>
              </div>
            </div>
            <button
              className="bg-red-500 px-10 py-3 rounded-3xl text-white font-bold my-3"
              onClick={() => setStep(4)}
            >
              NEXT
            </button>
          </div>
        )}

        {step === 4 && (
          <div>
            <img
              src="/BYD 1.png"
              width={200}
              alt="logo"
              className="py-5"
              data-aos="zoom-in"
            />
            <h2 class="text-2xl font-bold mb-4" data-aos="fade-right">
              Aturan Bermain Build Your Diviti
            </h2>
            <ol class="list-decimal">
              <li class="mb-4">
                <h3 class="text-lg" data-aos="fade-right">
                  Klik Start pada Stopwatch
                </h3>
              </li>
              <li class="mb-4">
                <h3 class="text-lg" data-aos="fade-right">
                  Mulai Merakit Diviti
                </h3>
              </li>
              <li class="mb-4">
                <h3 class="text-lg" data-aos="fade-right">
                  Klik Stop pada Stopwatch
                </h3>
              </li>
              <li class="mb-4">
                <h3 class="text-lg" data-aos="fade-right">
                  Waktu perakitan Diviti akan menjadi score yang anda peroleh.
                  Semakin cepat , score akan semakin besar
                </h3>
              </li>
            </ol>
            <p className="pb-3" data-aos="fade-left">
              Selamat bermain dan semoga Anda dapat merakit Diviti dengan cepat
              dan tepat!
            </p>
            <button
              className="bg-red-500 px-10 py-3 rounded-3xl text-white font-bold mb-8"
              onClick={() => setStep(5)}
            >
              NEXT
            </button>
          </div>
        )}

        {step === 5 && (
          <div>
            <img
              src="/BYD 1.png"
              width={200}
              alt="logo"
              className="py-5"
              data-aos="zoom-in"
            />
            <h2 class="text-2xl font-bold mb-4" data-aos="fade-right">
              Apakah anda siap?
            </h2>
            <button
              className="bg-red-500 px-10 py-3 rounded-3xl text-white font-bold mb-8 mr-3"
              onClick={() => setStep(3)}
            >
              Lihat Kembali Video
            </button>
            <button
              className="bg-red-500 px-10 py-3 rounded-3xl text-white font-bold mb-8"
              onClick={() => navigate("/stopwatch")}
            >
              NEXT
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Guidance;
