const initialState = {
    data_regist: null,
};

const reducer = ((state = initialState, action) => {
    let { type, data } = action;
    switch (type) {
        case "SET_DATA_REGIST":
            return {
                ...state,
                data_regist: data
            }
        default : 
            return state;
    }
})

export default reducer;